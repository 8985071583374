@import './../../../node_modules/bootstrap/scss/functions';
@import './../../../node_modules/bootstrap/scss/variables';
@import './../../../node_modules/bootstrap/scss/mixins';

// main colors
$color_primary: #3C4B66;
$color_primary_dark: #263146;
$color_primary_light: #257cc0;

$color_secondary: #f8c300;
$color_secondary_dark: #FFBB01;
$color_secondary_light: #ffd53e;

$color_third: #F87300;
$color_third_dark: #F87300;
$color_third_light: #F87300;

$color_inactive: #E4E4E4;
$color_inactive_secondary: #D4D4D4;
$color_separator: #707070;
$color_separator_light: #e0e0e0;

$color_thumbnails: #777;

// Sidebar menu
$color_sidebar: #ebedee;
$color_sidebar_active: #f9f9f9;

// focus
$color_primary_focus: #ffcc15;

// with opacity
$color_secondary_transparent: #ffcc1557;
$color_primary_transparent: rgba(197, 197, 197, 0.28);

// buttons
$color_btn_primary: #3C4B66;
$color_btn_primary_hover: #334057;
$color_btn_primary_active: #303c52;
$color_btn_primary_text: #fff;
$color_btn_secondary: #6c757d;
$color_btn_secondary_hover: #5c636a;
$color_btn_secondary_active: #565e64;
$color_btn_secondary_text: #fff;
$color_btn_signup: #f8c300;
$color_btn_signup_hover: #ffce1c;
$color_btn_signup_active: #ffce1c;
$color_btn_signup_text: #003F63;
$color_btn_more_fields: #4A965E;

// text
$color_text: #000;
$color_text_dark: #3C4B66;
$color_text_light: #f8f9fa;
$color_text_invert: #fff;

$color_error: #dc3545;
$color_alert: #FFF8E0;
$color_alert_text: #434343;
$color_inactive_text: #9CA0A1;
$color_date: #193E80;
$messages_background: #f1f2f2;
$highlight: #6612BC;

// Links
$color_link: #0d6efd;
$color_link_hover: #0a58ca;
$color_link_active: #0a58ca;

$color_input_cv: #f2f3f3;
$color_input_auth: #F5F5F5;
$color_input_auth_active: #fff;

// tab colors default
$color_tab_default_bg: var(--theme-color-primary);
$color_tab_default_text: var(--theme-color-text-invert);
$color_tab_orange_bg: var(--theme-color-secondary);
$color_tab_orange_text: var(--theme-color-primary);

$chat_conversation_selected: #FFFAE6;
$chat_message_balloon_others: #fff;
$color_new_date: #5a5a5a;

// accordion colors
$color_accordion_info_default: $color_input_auth;
$color_accordion_info_secondary: $color_inactive;
$color_accordion_exercise_default: #d6dbed;
$color_accordion_exercise_secondary: #c7cfe4;
$color_accordion_additional_default: #d8e9dd;
$color_accordion_additional_secondary: #c9e6d2;
$color_accordion_break_default: #fee1c9;
$color_accordion_break_secondary: #fdc391;

:root {
    --theme-color-primary:                       #{$color_primary};
    --theme-color-primary-dark:                  #{$color_primary_dark};
    --theme-color-primary-light:                 #{$color_primary_light};

    --theme-color-secondary:                     #{$color_secondary};
    --theme-color-secondary-dark:                #{$color_secondary_dark};
    --theme-color-secondary-light:               #{$color_secondary_light};

    --theme-color-third:                         #{$color_third};
    --theme-color-third-dark:                    #{$color_third_dark};
    --theme-color-third-light:                   #{$color_third_light};

    --theme-color-text:                          #{$color_text};
    --theme-color-text-dark:                     #{$color_text_dark};
    --theme-color-text-light:                    #{$color_text_light};
    --theme-color-text-invert:                   #{$color_text_invert};

    --theme-color-link:                          #{$color_link};
    --theme-color-link-hover:                    #{$color_link_hover};
    --theme-color-link-active:                   #{$color_link_active};

    --theme-color-sidebar:                       #{$color_sidebar};
    --theme-color-sidebar-active:                #{$color_sidebar_active};
    --theme-color-sidebar-text:                  #{$color_primary};
    --theme-color-sidebar-text-hover:            #{$color_primary};
    --theme-color-sidebar-text-active:           #{$color_primary};

    --theme-color-btn-primary:                   #{$color_btn_primary};
    --theme-color-btn-primary-hover:             #{$color_btn_primary_hover};
    --theme-color-btn-primary-active:            #{$color_btn_primary_active};
    --theme-color-btn-primary-text:              #{$color_btn_primary_text};

    --theme-color-btn-secondary:                 #{$color_btn_secondary};
    --theme-color-btn-secondary-hover:           #{$color_btn_secondary_hover};
    --theme-color-btn-secondary-active:          #{$color_btn_secondary_active};
    --theme-color-btn-secondary-text:            #{$color_btn_secondary_text};

    --theme-color-btn-signup:                    #{$color_btn_signup};
    --theme-color-btn-signup-hover:              #{$color_btn_signup_hover};
    --theme-color-btn-signup-active:             #{$color_btn_signup_active};
    --theme-color-btn-signup-text:               #{$color_btn_signup_text};
}
