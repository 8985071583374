// PrimeNG
@import "./../node_modules/primeicons/primeicons.css";
@import "./../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "./../node_modules/primeng/resources/primeng.min.css";

// Font AWesome
@import "./../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "./../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "./../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "./../node_modules/@fortawesome/fontawesome-free/scss/brands";

// Common
@import "assets/styles/icons";
@import "assets/styles/fonts";
@import "assets/styles/colors";
@import "assets/styles/shared";

// Bootstrap 5
@import "./../node_modules/bootstrap/scss/bootstrap";
@import "assets/styles/bootstrap";

@import "assets/styles/primeng";
@import "assets/styles/material";

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    letter-spacing: normal !important;
}

a {
    text-decoration: none;
}
