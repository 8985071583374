@use "sass:math";

$font_family_icons: "Font Awesome 6 Free";

$font_default_min_vw: 320px;
$font_default_max_vw: 1200px;

@function strip-unit($value) {
    @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($max-font-size:24px, $min-font-size:16px, $min-vw: $font_default_min_vw, $max-vw: $font_default_max_vw, $isImportant: false) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}))#{if($isImportant, ' !important', '')};
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size#{if($isImportant, ' !important', '')};
            }
        }
    }
}

@mixin font_xl($isImportant: false) {
    @include fluid-type(32px, 20px, $font_default_min_vw, $font_default_max_vw, $isImportant);
}

@mixin font_lg($isImportant: false) {
    @include fluid-type(24px, 18px, $font_default_min_vw, $font_default_max_vw, $isImportant);
}

@mixin font_md($isImportant: false) {
    @include fluid-type(20px, 16px, $font_default_min_vw, $font_default_max_vw, $isImportant);
}

@mixin font_sm($isImportant: false) {
    @include fluid-type(16px, 14px, $font_default_min_vw, $font_default_max_vw, $isImportant);
}

@mixin font_xs($isImportant: false) {
    @include fluid-type(14px, 12px, $font_default_min_vw, $font_default_max_vw, $isImportant);
}
