.p-inputtext,
.p-component {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: normal;
}

p-dropdown,
p-multiselect {
    margin: 10px 0;
    display: block;

    &.is-invalid {
        border: 1px solid $color_error;
        border-radius: 12px;
    }

    .p-inputtext {
        color: var(--theme-color-text);
    }

    .p-dropdown,
    .p-multiselect {
        height: 44px;
        border: 0;
        border-radius: 12px;
        color: var(--theme-color-text);
        background-color: rgba(156, 160, 161, 0.13);
        box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.161);

        &.p-focus,
        &.p-dropdown-open,
        &.p-multiselect-open,
        &.p-inputwrapper-focus {
            box-shadow: none !important;
        }
    }
}

.p-dropdown-panel,
.p-multiselect-panel {
    .p-multiselect-header {

        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
            // color: var(--theme-color-text-invert);
        }

        .p-checkbox .p-checkbox-box.p-highlight {
            background: var(--theme-color-primary-light);
            border-color: var(--theme-color-primary-light);

            svg {
                color: var(--theme-color-text-invert);
            }
        }
    }

    &.mh-60vh {
        max-height: 60vh;
        overflow-y: auto;
    }
}

.p-dropdown-panel .p-dropdown-items,
.p-multiselect-panel .p-multiselect-items {
    padding: 0.5rem 0;
    margin: 0;

    .p-multiselect-item,
    .p-dropdown-item {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &:not(.disabled):hover {
            background: #e9ecef;
        }


        &:not(.p-highlight):not(.p-disabled) {
            background-color: transparent;
            color: var(--theme-color-text);
        }

        &.p-highlight:not(.disabled),
        &.p-highlight:not(.p-disabled) {
            background-color: var(--theme-color-primary-light) !important;
            color: var(--theme-color-text-invert) !important;
        }

        &.p-highlighted-option:not(.disabled),
        &.p-highlighted-option:not(.p-disabled),
        &.p-highlight:not(.disabled):active,
        &.p-highlight:not(.disabled):focus,
        &.p-highlight:not(.p-disabled).p-focus {
            box-shadow: none !important;

            .p-inputtext {
                color: var(--theme-color-text-invert);
            }
        }

        &:not(.p-highlight):not(.disabled):focus,
        &:not(.p-highlight):not(.p-disabled).p-focus {
            background-color: transparent;
            color: var(--theme-color-text);
        }

        &:not(.p-highlight):not(.disabled):active,
        &.p-highlight:not(.disabled):active,
        &.p-highlight:not(.disabled):focus,
        &.p-highlight:not(.p-disabled).p-focus {
            background-color: var(--theme-color-primary-dark);
            color: var(--theme-color-text-invert);

            .p-inputtext {
                color: var(--theme-color-text-invert);
            }

            .text-muted {
                color: var(--theme-color-text-invert) !important;
            }
        }
    }

    .p-multiselect-item {
        .p-checkbox .p-checkbox-box.p-highlight {
            background: var(--theme-color-text-light);
            border-color: var(--theme-color-primary-light);

            svg {
                color: var(--theme-color-text-dark);
            }
        }
    }
}

.dropdown-panel-fixed-width {
    max-width: min(350px, 90vw);
}

p-multiselect {
    margin: 10px 0;

    &.is-invalid {
        border: 1px solid $color_error;
        border-radius: 12px;
    }

    .p-multiselect {
        width: 100%;

        .p-multiselect-label {
            padding-left: 20px;
        }

        &.p-multiselect-chip .p-multiselect-label {
            &:not(.p-placeholder) {
                padding-left: 4px !important;
            }

            &.p-placeholder {
                color: #6c757d;
            }
        }

        .p-multiselect-token {
            border-radius: 10px !important;
            background: #ddd !important;
            border: 1px solid #bbb !important;
            color: black !important;
            margin-right: 4px;
            transition: all 0.2s;

            &:hover {
                background: #d0d0d0 !important;
            }

            &:last-child {
                margin-right: 0;
            }

            .p-multiselect-token-label ~ .p-icon-wrapper {
                color: $color_error;
            }

            .p-multiselect-token-label ~ .p-icon-wrapper:hover {
                color: #fd5364 !important;
            }
        }
    }
}

.p-datepicker {
    .p-datepicker-header .p-datepicker-prev,
    .p-datepicker-header .p-datepicker-next,
    .p-timepicker button {
        border-radius: 50%;
    }
}

.p-datepicker .p-datepicker-buttonbar {
    padding: 0.75rem 0.5rem 0.25rem;
}

p-calendar {
    .p-calendar {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;

        .p-inputtext {
            @include font_sm;
            width: auto;
            color: var(--theme-color-text);
            border: 0;
            background-color: rgba(156, 160, 161, 0.13);
            box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.161);
            border-radius: 12px;
            padding: 10px 20px;
            margin: 10px 0;
            width: 90%;
            font-weight: 400;
            line-height: 1.5;

            &:hover, &:focus {
                cursor: pointer;
            }
        }

        .p-button:not(.btn) {
            color: gray;
            background-color: transparent;
            outline: none !important;
            border: none;
            position: absolute;
            right: 15px;
            width: 10%;

            .p-icon {
                width: 1.3rem;
                height: 1.3rem;
            }

            &:active {
                box-shadow: none !important;;
            }

            &:hover, &:focus {
                border: none !important;
                box-shadow: none;
                background-color: transparent;
            }
        }

        &:hover {
            .p-inputtext + .p-button {
                color: #aaa;
                cursor: pointer;
            }
        }

        .p-inputtext:enabled:focus {
            box-shadow: none;
        }

        &.p-calendar-w-btn {
            .p-inputtext {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }

            .p-button-icon-only {
                right: 12px;
                width: 32px;
                height: 32px;
                padding: 0;

                calendaricon svg {
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }

    &.is-invalid .p-calendar.p-calendar-w-btn .p-button-icon-only {
        display: none !important;
    }
}

.p-datepicker-other-month {
    >.p-element {
        display: none;
    }
}

p-breadcrumb .p-breadcrumb ul {
    white-space: nowrap;
}

.custom-p-carousel {
    .p-carousel .p-carousel-container,
    .p-carousel .p-carousel-items-content {
        height: auto !important;
    }

    .p-carousel-item {
        height: 0;
    }

    .p-carousel-item.p-carousel-item-active {
        height: auto;
    }

    .p-link {
        background-color: var(--theme-color-btn-primary) !important;
        opacity: 0.35;
        border-radius: 50% !important;
        width: 10px !important;
        height: 10px !important;
    }

    .p-highlight {
        .p-link {
            background-color: var(--theme-color-btn-primary) !important;
            opacity: 1.0;
            box-shadow: none;
        }
    }
}

.p-contextmenu {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.175);

    .p-menuitem {
        overflow: hidden;

        &, span {
            color: #1e2125 !important;
        }

        & > .p-menuitem-link {
            font-family: "Roboto", sans-serif;
            padding: 0.45rem 1rem;
            margin: 2px 0;
        }

        &.p-menuitem-active {
            & > .p-menuitem-link {
                &, span {
                    color: #1e2125 !important;
                }
                background-color: #e9ecef;
            }
        }
    }

}

.p-dialog {

    .p-dialog-header {
        background-color: var(--theme-color-primary);
        color: var(--theme-color-text-invert);
        text-align: center;
        padding: 1.0rem 1.5rem;

        @media screen and (max-width: 991px) {
            @include font_md;
            padding: 5px;
        }

        @media screen and (max-height: 767px) {
            @include font_md;
            padding: 5px;
        }

        .p-dialog-title{
            @include font_md;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0 auto;

            @media screen and (max-height: 991px) {
                @include font_sm;
            }
        }

        .p-dialog-header-icon {
            border-radius: 50%;
        }

        & + .p-dialog-content {
            padding-top: 1rem;
        }
    }



    .p-dialog-content {
        // Safari border fix
        border-bottom: 1px solid #fff;
    }

    .p-dialog-footer button {
        margin: 0 0.5rem 0 0;
        width: auto;
    }
}

/* Style the overlay panel to look like a Bootstrap dropdown */
.p-overlaypanel {
    border: 1px solid #ccc;
    color: var(--theme-color-text);
    box-shadow: none;
    margin-top: 0 !important;
    padding: 0.5rem 0;
    min-width: 10rem;

    &::before, &::after {
        content: none !important;
    }

    .p-overlaypanel-content {
        padding: 0;

        a.custom-dropdown-item {
            color: var(--theme-color-text);
        }

        .custom-dropdown-item {
            padding: 0.25rem 1rem;
            align-items: center;
            clear: both;
            font-weight: 400;
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
            display: block;
            width: 100%;
            cursor: pointer;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            font-family: "Roboto", "Helvetica Neue", sans-serif;
            transition:
                color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out
            ;

            i {
                margin-right: 5px;
                vertical-align: baseline;
            }

            &:first-child,
            &:last-child {
                border-radius: 0 !important;
            }
        }

        .custom-dropdown-item:nth-child(0) {
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
        }

        .custom-dropdown-item:last-child {
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        .custom-dropdown-item:hover {
            text-decoration: none;
            background-color: #e9ecef !important;
        }

        .dropdown-divider {
            margin: 0.5rem 0;
            padding: 0;
            height: 0;
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.175);
            overflow: hidden;
        }
    }
}

p-galleria {
    p-galleriaitem {
        .p-galleria-item {
            .p-galleria-item-container button .p-icon {
                filter: drop-shadow(1px 1px 2px #ccc);
            }

            img {
                width: 100%;
                display: block;
                max-width: 90vw;
                height: 100%;
                max-height: 90vh;
                margin: 0 auto;
            }
        }
    }
}
