.main-content {
    margin-top: 0 !important;

    @media screen and (min-width: 992px) {
        margin-top: 35px !important;
    }
}

.btn-link {
    padding: 0;
    margin-bottom: 16px;
    color: var(--theme-color-third);
    text-decoration: none!important;
    &:hover {
        color: var(--theme-color-primary-dark)!important;
    }
}
.separator {
    height: 0;
    margin-top: 7px;
    color: var(--theme-color-primary);

    span {
        display: inline-block;
        width: 100px;
        height: 9px;
        border-radius: 10px;
        background-color: var(--theme-color-primary);
    }

    i {
        font-size: 36px;
    }

    &.secondary {
        span {
            background-color: var(--theme-color-secondary);
        }

        i {
            color: var(--theme-color-secondary);
        }
    }

    &.third {
        span {
            background-color: var(--theme-color-third);
        }

        i {
            color: var(--theme-color-third);
        }
    }
}

/*
    Side Component
*/
.side-content {
    background-color: rgba(156, 160, 161, 0.11);
    padding: 30px 0;
    border-radius: 0 0 0 30px;

    &:empty {
        background: transparent !important;
    }

    @media screen and (max-width: 1199px) {
        background-color: rgba(156, 160, 161, 0);
    }

    & > div {
        border-bottom: 1px solid #ccc;

        @media screen and (max-width: 1199px) {
            border-bottom: none;
        }
    }

    & > div:last-child {
        border-bottom: none;
    }

    h3 {
        @include font_lg;
        font-weight: bold;
        color: var(--theme-color-text-dark);

        .separator {
            span {
                width: 60px;
                height: 6px;
            }

            i {
                font-size: 30px;
            }
        }
    }

    .side-icon {
        padding-bottom: 35px;
    }
}

.video-wrapper {
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    video,
    iframe {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        overflow: hidden !important;
        box-shadow: 0 0 2px black;
        z-index: 1;
    }
}

.video-content.dynamic-content {
    figure {
        height: 100%;
        margin: 0;

        & > div {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        & > div,
        & > div > div {
            height: 100% !important;
        }
    }

}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
    color: #ffffff;
    background: transparent!important;
    border-color: transparent!important;
}
.danger {
    color: var(--theme-color-third);
}
.warning {
    color: var(--theme-color-secondary-light);
}
.btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: none!important;
}

// checkboxes

.container-checkbox {
    white-space: nowrap;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include font_sm;
    color: var(--theme-color-text);
}

.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.container-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #88B9E1;
    border-radius: 5px;
    opacity: 1;
}
.container-checkbox input:checked~.checkmark {
    background-color: #2196F3;
}
.container-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-checkbox input:checked~.checkmark:after {
    display: block;
}
.container-checkbox .checkmark:after {
    left: 6px;
    top: 0px;
    width: 10px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.form-control:focus {
    box-shadow: none!important;
}
.cross-validation-error {
    color: var(--theme-color-third) !important;
    display: block;
}

input.is-invalid,
select.is-invalid,
textarea.is-invalid {
    border: 1px solid $color_error !important;
}

.p-caroucel-box{
    padding: 10px 0px;
    height: 100%;
}

@media screen and (max-width: 981px) {
    .justify-sm-content-between {
        justify-content: space-between;
    }
    p-toast .p-toast {
        max-width: calc(100% - 40px);
    }
}

::ng-deep .p-caroucel-box {
    height: 100%!important;
}

.dynamic-content {
    table {
        width: 100%;

        h4{
            margin: 0;
            margin-top: 5px;
        }

        p{
            margin: 0;
            margin-top: 5px;
            @include font_sm;
        }

        span{
            margin: 0 !important;
            margin-top: 5px !important;
        }
    }

    img {
        max-width: 50% !important;
        width: auto;
        height: auto;
        object-fit: contain;
        border-radius: 4px;
        margin: 5px;

        &:hover {
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
        }
    }

    .materials {
        .materials-title {
            border: 2px dashed  var(--theme-color-secondary);
            color: var(--theme-color-text-dark);
            @include font_sm;

            h3 {
                color: var(--theme-color-text-dark);
                @include font_lg;
                margin-bottom: 15px;
            }

            ul {
                li::marker {
                    color: var(--theme-color-secondary);
                }
            }

            i {
                color: var(--theme-color-secondary);
            }
        }

        .materials-content {
            .accordion-item {
                border: none;
            }

            .accordion-button {
                color: var(--theme-color-secondary);
                @include font_md;
                background-color: $messages_background;

                p,
                span {
                    @include font_sm;
                    padding: 15px 25px;
                    line-height: 1rem;
                    i {
                        @include font_xs;
                    }
                }

                span {
                    background-color: $color_inactive;
                }

                &:not(.collapsed) {
                    background-color: $color_inactive;
                    color: var(--theme-color-primary);
                }

                &::after {
                    position: absolute;
                    right: 10px;
                }

                &.info {
                    background-color: $color_accordion_info_default;

                    span {
                        background-color: $color_accordion_info_secondary;
                    }

                    &:not(.collapsed) {
                        background-color: $color_accordion_info_default;
                    }
                }

                &.exercise {
                    background-color: $color_accordion_exercise_default;

                    span {
                        background-color: $color_accordion_exercise_secondary;
                    }
                    &:not(.collapsed) {
                        background-color: $color_accordion_exercise_default;
                    }
                }

                &.additional, &.homework {
                    background-color: $color_accordion_additional_default;

                    span {
                        background-color: $color_accordion_additional_secondary;
                    }

                    &:not(.collapsed) {
                        background-color: $color_accordion_additional_default;
                    }
                }

                &.break {
                    background-color: $color_accordion_break_default;

                    span {
                        background-color: $color_accordion_break_secondary;
                    }

                    &:not(.collapsed) {
                        background-color: $color_accordion_break_default;
                    }
                }
            }

            .accordion-body {
                @include font_sm;
                color: var(--theme-color-text-dark);

                h3 {
                    @include font_md;
                    font-weight: bold;
                    margin: 20px 0 0;
                }

                p {
                    text-indent: 30px;
                    line-height: 1.5em;
                }

                ul {
                    li::marker {
                        color: var(--theme-color-secondary);
                    }
                }
            }
        }

        .p-accordion {

            .p-accordion-tab {
                margin: 0;
                .p-accordion-header {
                    color: var(--theme-color-primary);
                    @include font_md;
                    background-color: $messages_background;

                    .p-accordion-header-link {
                        padding: 0;
                        box-shadow: none;
                        border: 0;

                        font-weight: normal;
                        color: var(--theme-color-inactive-text);
                        &:hover {
                            color: var(--theme-color-text);
                        }
                    }

                    .p-icon-wrapper {
                        position: absolute;
                        right: 5px;
                    }

                    .p-accordion-header-text {
                        display: none;
                    }

                    p,
                    span {
                        @include font_sm;
                        padding: 15px 25px;
                        line-height: 1rem;
                        i {
                            @include font_xs;
                        }
                    }

                    span {
                        background-color: $color_inactive;
                    }

                    &:not(.collapsed) {
                        background-color: $color_inactive;
                        color: var(--theme-color-primary);
                    }

                    &::after {
                        position: absolute;
                        right: 10px;
                    }

                    .info {
                        background-color: $color_accordion_info_default;

                        span {
                            background-color: $color_accordion_info_secondary;
                        }

                        &:not(.collapsed) {
                            background-color: $color_accordion_info_default;
                        }
                    }

                    .exercise {
                        background-color: $color_accordion_exercise_default;

                        span {
                            background-color: $color_accordion_exercise_secondary;
                        }
                        &:not(.collapsed) {
                            background-color: $color_accordion_exercise_default;
                        }
                    }

                    .additional, .homework {
                        background-color: $color_accordion_additional_default;

                        span {
                            background-color: $color_accordion_additional_secondary;
                        }

                        &:not(.collapsed) {
                            background-color: $color_accordion_additional_default;
                        }
                    }

                    .break {
                        background-color: $color_accordion_break_default;

                        span {
                            background-color: $color_accordion_break_secondary;
                        }

                        &:not(.collapsed) {
                            background-color: $color_accordion_break_default;
                        }
                    }
                }

                .p-accordion-content {
                    border: 0;

                    .accordion-body {
                        @include font_sm;
                        color: var(--theme-color-text-dark);
                        padding: 0;

                        h3 {
                            @include font_md;
                            font-weight: bold;
                            margin: 20px 0 0;
                        }

                        p {
                            text-indent: 30px;
                            line-height: 1.5em;
                        }

                        ul {
                            li::marker {
                                color: var(--theme-color-secondary);
                            }
                        }
                    }
                }
            }
        }
    }

    &.white-links {
        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

    }
}

.dynamic-content-career-content {
    h3,
    p:first-of-type {
        font-weight: 600;
        @include font_md;
        color: $color_inactive_text;
        margin-bottom: 20px;
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            position: relative;
            padding-left: 25px;
            font-weight: 300;
            @include font_sm;
            line-height: 22px;
            &:before {
                content: '';
                width: 25px;
                height: 25px;
                display: block;
                position: absolute;
                left: 0;
                top: -2px;
                clip-path: polygon(25% 25%, 58.98% 44.94%, 58.98% 53.2%, 25% 75%);
                background-color: var(--theme-color-secondary);
            }
        }
    }

    img {
        max-width: 100%;
        height: auto;
        width: auto;
        object-fit: cover;
    }
}
.dynamic-content-career-infobox {
    a {
        color: var(--theme-color-secondary);
        transition: all 0.2s;
        text-decoration: none;

        &:hover {
            color: var(--theme-color-text);
            text-decoration: underline;
        }
    }
}

.nice-scrollbars {
    scrollbar-color: var(--theme-color-secondary) #f1f2f2;
    scrollbar-width: thin;
    scrollbar-width: 10px;

    &::-webkit-scrollbar {
        height: 10px;
        width: 10px;
        background: var(--theme-color-secondary);
    }

    &::-webkit-scrollbar-track {
        background: #f1f2f2;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--theme-color-secondary);
        border-radius: 12px;
    }
}


#ngx-colors-overlay {
    ngx-colors-panel > div {
        border-radius: 0.375rem;
    }

    button {

        background-color: var(--theme-color-btn-primary);
        color: var(--theme-color-btn-primary-text);
        border-radius: 0.5rem;
        margin: 0 0.25rem;
        padding: 0.375rem 0.625rem;

        &:hover {
            background-color: var(--theme-color-btn-primary-hover);
            color: var(--theme-color-btn-primary-text);
        }

        &:active {
            background-color: var(--theme-color-btn-primary-active);
            color: var(--theme-color-btn-primary-text);
        }
    }
}
