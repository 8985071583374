@import '/src/assets/styles/fonts';
@import './../../../node_modules/bootstrap/scss/functions';
@import './../../../node_modules/bootstrap/scss/mixins';
@import './../../../node_modules/bootstrap/scss/variables';

a {
    color: var(--theme-color-link);

    &:hover {
        color: var(--theme-color-link-hover);
    }
    &:active {
        color: var(--theme-color-link-active);
    }
}

// bootstrap
.btn {
    border-radius: 0.5rem;

    &.btn-outline-primary,
    &.btn-primary {
        --bs-btn-color: var(--theme-color-btn-primary-text);
        --bs-btn-bg: var(--theme-color-btn-primary);

        --bs-btn-border-color: var(--theme-color-btn-primary);
        --bs-btn-hover-color: var(--theme-color-btn-primary-text);

        --bs-btn-hover-bg: var(--theme-color-btn-primary-hover);
        --bs-btn-hover-border-color: var(--theme-color-btn-primary-hover);

        --bs-btn-active-color: var(--theme-color-btn-primary-text);
        --bs-btn-active-bg: var(--theme-color-btn-primary-active);
        --bs-btn-active-border-color: var(--theme-color-btn-primary-active);

        --bs-btn-disabled-color: var(--theme-color-btn-primary-text);
        --bs-btn-disabled-bg: var(--theme-color-btn-primary);
        --bs-btn-disabled-border-color: var(--theme-color-btn-primary);
    }

    &.btn-outline-primary {
        --bs-btn-color: var(--theme-color-btn-primary);
        --bs-btn-bg: transparent;
    }

    &.btn-outline-secondary,
    &.btn-secondary {
        --bs-btn-color: var(--theme-color-btn-secondary-text);
        --bs-btn-bg: var(--theme-color-btn-secondary);

        --bs-btn-border-color: var(--theme-color-btn-secondary);
        --bs-btn-hover-color: var(--theme-color-btn-secondary-text);

        --bs-btn-hover-bg: var(--theme-color-btn-secondary-hover);
        --bs-btn-hover-border-color: var(--theme-color-btn-secondary-hover);

        --bs-btn-active-color: var(--theme-color-btn-secondary-text);
        --bs-btn-active-bg: var(--theme-color-btn-secondary-active);
        --bs-btn-active-border-color: var(--theme-color-btn-secondary-active);

        --bs-btn-disabled-color: var(--theme-color-btn-secondary-text);
        --bs-btn-disabled-bg: var(--theme-color-btn-secondary);
        --bs-btn-disabled-border-color: var(--theme-color-btn-secondary);
    }

    &.btn-outline-secondary {
        --bs-btn-color: var(--theme-color-btn-secondary);
        --bs-btn-bg: transparent;

    }
}

.standard-form {
    label {
        @include font_sm;
        font-weight: normal;

        &.required:after {
            content: "*";
            color: $color_error;
        }
    }
    .form-control {
        color: var(--theme-color-text);
        background-color: rgba(156, 160, 161, 0.13);
        box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.161);
        border-radius: 12px;
        padding: 10px 20px;
        margin: 10px 0;
        @include font_sm;

        &:not(.is-invalid) {
            border: none;
        }

        &[disabled],
        &:disabled,
        &.disabled {
            color: darken($color_inactive, 50%);
            cursor: not-allowed;
        }
    }

    .is-invalid + .invalid-feedback {
        @include font_xs;
    }

    .is-invalid .ck-editor {
        border: 1px solid $color_error;
    }
    .ck-editor {
        margin: 10px 0 !important;
        border-radius: 12px;
        box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.161);
        border: none;

        .ck-toolbar {
            border: none;
            background: rgba(156, 160, 161, 0.13);
            border-radius: 12px 12px 0 0 !important;

            .ck-font-size-dropdown .ck-list__item button span {
                font-size: 1.0rem !important;
                line-height: normal;
            }

            .ck-toolbar__items {
                .ck.ck-button:not(.ck-disabled):hover,
                a.ck.ck-button:not(.ck-disabled):hover {
                    outline: 1px solid #aaa;
                    background-color: #eee;
                    border-radius: 6px;
                    cursor: pointer;
                }
            }
        }

        .ck-sticky-panel__content_sticky {
            background: #f5f5f5;

            .ck-toolbar {
                border-radius: 0 !important;
            }
        }

        .ck.ck-sticky-panel__content {
            border: none !important;
        }

        .ck-editor__editable_inline {
            min-height: 20vh;
            border: none;
            border-radius: 12px !important;
            overflow: hidden;

            &.ck-focused {
                box-shadow: none !important;
                border: none !important;
            }
        }
    }

    .submit-panel {
        position: sticky;
        bottom: 0;
        z-index: 11;
    }
}

.ck-body-wrapper {
    .ck-powered-by-balloon,
    .ck-powered-by__label,
    .ck-powered-by {
        display: none !important;
    }
}

// add .order- classes up to 32
@for $i from 6 through 32 {
    .order-#{$i} {
        order: $i;
    }
    @media (min-width: 576px) {
        .order-sm-#{$i} {
            order: $i;
        }
    }
    @media (min-width: 768px) {
        .order-md-#{$i} {
            order: $i;
        }
    }
    @media (min-width: 992px) {
        .order-lg-#{$i} {
            order: $i;
        }
    }
    @media (min-width: 1200px) {
        .order-xl-#{$i} {
            order: $i;
        }
    }
    @media (min-width: 1400px) {
        .order-xxl-#{$i} {
            order: $i;
        }
    }
}

.search-field-bar {
    z-index: 1;

    &.form-control {
        border-color: #C5C5C5;
        border-width: 1px;
        border-radius: 12px !important;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        padding-left: 0;
        box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.24);
    }

    input {
        border: 0;
        background-color: transparent;
        padding: 9px 10px;
        border-radius: 12px !important;

        &:focus {
            box-shadow: none;
        }
    }

    .btn {
        color: var(--theme-color-btn-primary);
        border: 0 !important;
        position: relative;
        background-color: transparent !important;

        &:hover {
            color: var(--theme-color-btn-primary-hover);
        }
        &:active {
            color: var(--theme-color-btn-primary-active);
        }

        &.submit {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            width: 48px;
            text-align: center;
        }
    }
}

.dropdown-menu {
    .dropdown-item:not(:disabled) {
        color: var(--theme-color-text) !important;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .dropdown-item:not(:disabled).active,
    .dropdown-item:not(:disabled):active {
        color: var(--theme-color-text-invert) !important;
        background-color: var(--theme-color-primary-dark) !important;
        text-decoration: none;
    }
}

.form-switch .form-check-input {
    &:checked {
        border-color: var(--theme-color-link);
        background-color: var(--theme-color-link);
    }

    &:focus {
        box-shadow: none;
    }
}

.text-keep-newlines {
    white-space: pre-line;
    word-break: break-word;
}

.custom-popover .popover-body {
    max-height: 80vh;
    overflow: auto;
}
